<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Manual Product Recommendations</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div v-for="category in categories" :key="category.id">
            <label class="cstm-check dropdown-item">
              <span class="text-black mx-2 regular-14">{{
                category.name
              }}</span>
              <input
                type="checkbox"
                v-model="selectedCategories"
                :value="category.id"
              /><span class="checkmark mx-2"></span>
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="save"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
const bootstrap = require("bootstrap");

export default {
  name: "ManualProductRecommendations",
  props: {
    modelValue: Number,
    categories: Array,
    values: Array,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      myModal: null,
      success: false,
      loadAddBtn: false,
      selectedCategories: [],
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
  },
  watch: {
    values: {
      deep: true,
      handler() {
        this.selectedCategories = [];
        for (const value of this.values) {
          this.selectedCategories.push(value.product_category_id);
        }
      },
    },
  },
  methods: {
    showModal() {
      this.myModal.show();
    },
    save() {
      http
        .fetch(`/products/products/manual-recommendations`, {
          categories: this.selectedCategories,
          variant_id: this.modelValue,
        })
        .then(() => {
          this.myModal.hide();
        });
    },
  },
};
</script>
